
<template>
  <div class="BusinessPeopleSelection">
    <van-search v-model="name" clearable class="search" :placeholder="$t('请输入姓名搜索')" @search="search" @clear="search"/>
    <CommonIndexBar
      :ref="`B_PEOPLE_SELECTION_${field}`"
      v-bind="$attrs"
      :content="content"
      titleKey="name"
      :isSelection="true"
      :height="height"
      :multiple="multiple"
      @change="handleSelectChange"
    />
  </div>
</template>

<script>
// Components
import CommonIndexBar from '@/components/v2/system/CommonIndexBar/index.vue'
import { mapGetters } from 'vuex'

import baseDataServices from '@/services/baseDataServices'
export default {
  components: {
    CommonIndexBar,
  },
  props: {
    shopIds: {
      type: String,
      default: '',
    },
    roleCodes: {
      type: [Array,String],
      default: '',
    },
    field: {
      type: String,
      default: 'businessPepole',
    },
    multiple:{
      type: [Boolean,String],
      default: false
    },
    height:{
      type: String,
      default:''
    },
    filterData:{
      type: Function,
      default:(data)=>{ return data }
    },
    filterIds: {
      type: Array,
      default: () => [],
    },
    withPermission:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: {},
      name:''
    }
  },
  computed: {
    // ...mapGetters(['shops']),
    ...mapGetters([
      'userInfo',
      'selectedShopId'
    ]),
  },
  watch: {
    userInfo:{
      handler(val){
        this.getContent()
      },
      immediate: true,
    }
  },
  methods: {
    search() {
      this.getContent()
    },
    getContent() {
      const { roleCodes,userInfo,name } = this      
      let dealerId = ''
      if (userInfo && userInfo.dealers && userInfo.dealers[0]){
        // dealerId = this.shopIds
        //   ? this.shopIds
        //   : (this.selectedShopId || userInfo.dealers[0]?.id)
        dealerId = this.selectedShopId || userInfo.dealers[0]?.id
      }
      if (!roleCodes || !dealerId) return
      baseDataServices
        .getListEmpToMap({
          roleLogo:roleCodes,
          dealerId,
          name,
          withPermission: this.withPermission // 仅查询权限范围内的
        })
        .then((data) => {
          this.content = this.filterData(data)
        })
    },
    handleSelectChange(values) {
      this.$emit(
        'change',
        values.map(({ id, name }) => ({
          name: name,
          value: id,
          label: name,
          id,
        }))
      )
    },

    // 对外暴露的方法
    CHECK_OFF_ITEM(tagValue) {
      console.log('CHECK_OFF_ITEM', tagValue)
      const refName = `B_PEOPLE_SELECTION_${this.field}`
      const refNode = this.$refs[refName]
      refNode.CHECK_OFF_ITEM(tagValue)
    },
  },
}
</script>

<style lang="less" scoped>
.BusinessPeopleSelection{
  .search /deep/ .van-field{
    background-color: transparent !important;
  }
}
</style>
