import salesAssistantPortalLang from '@/modules/sales-assistant-portal/lang/en-US'
import salesAssistantLang from '@/modules/salesAssistant/lang/en-US'
import deliverManageLang from '@/modules/deliverManage/lang/en-US'
import orderLang from '@/modules/order/lang/en-US'
import invalidManage from '@/modules/invalidManage/lang/en-US'
import clueDefeat from '@/modules/clueDefeat/lang/en-US'
import carManage from '@/modules/carManage/lang/en-US.js'
import testDrive from '@/modules/testDrive/lang/en-US.js'
import offlineOrder from '@/modules/offlineOrder/lang/en-US'
import approveManage from '@/modules/approveManage/lang/en-US'
import charge from '@/modules/charge/lang/en-US'
import testDriveReport from '@/modules/testDriveReport/lang/en-US'
import specialCarStock from '@/modules/specialCarStock/lang/zh-CN'

export default {
  ...salesAssistantPortalLang,
  ...testDrive,
  ...salesAssistantLang,
  ...deliverManageLang,
  ...invalidManage,
  ...clueDefeat,
  ...carManage,
  ...offlineOrder,
  ...approveManage,
  ...charge,
  ...orderLang,
  ...testDriveReport,
  ...specialCarStock,
  '最新跟进时间': 'Latest Follow-up Time',
  '试驾意向': 'Test Drive Intention',
  '购车意向': 'Purchase Intention',
  '选配信息': 'Customization Information',
  '试驾人数': 'Number of Test Drive Participants',
  '客户顾虑': 'Customer Concerns',
  '客户顾虑补充': 'Additional Customer Concerns',
  '请输入客户顾虑补充': 'Please Enter Additional Customer Concerns',
  '具体取消原因': 'Specific Cancellation Reason',
  '请输入具体取消原因': 'Please Enter Specific Cancellation Reason',
  '查询': 'Inquiry',
  '总部锁定状态': 'Headquarters Lock Status',
  '展车': 'Display Vehicle',
  '合同签署状态': 'Contract Signing Status',
  '实质性退单': 'Substantive Order Cancellation',
  '非实质性退单': 'Non-substantive Order Cancellation',
  '退单原因': 'Order Cancellation Reason',
  '申请描述': 'Application Description',
  '请输入申请描述': 'Please Enter Application Description',
  '申请材料': 'Application Materials',
  '请选择退单原因': 'Please Select Order Cancellation Reason',
  '订单编号': 'Order Number',
  '实质性退单表示客户有切实退单需求，而非外部因素导致需要先退单再重新下单': 'Substantive order cancellation indicates that the customer has a genuine intention to cancel the order, not due to external factors requiring a cancellation followed by a re-order.',
  '大区/城市区域/交付门店有联动关系；': 'There is an interrelation between Region/Area/City and Delivery Store;',
  '门店省份/门店城市/交付门店有联动关系；': 'There is an interrelation between Store Province/Store City and Delivery Store;',
  请上传正确图片文件: 'Please upload the correct image file',
  拒绝原因: 'Reason for rejection',
  改派前门店: 'Original store before reassignment',
  改派后门店: 'New store after reassignment',
  请输入拒绝原因: 'Please enter the reason for rejection',
  中央客服改派线索: 'Central customer service reassignment leads',
  中央客服: 'Central customer Service',
  选择更多渠道: 'Select more channels',
  最近使用渠道: 'Recently used channels',
  请输入完整VIN号或后8位查询: 'Full VIN number or the last 8 digits',
  权益明细: 'Rights details',
  权益类型: 'Type of rights',
  权益价值: 'Value of rights',
  权益数量: 'Quantity of rights',
  权益Code: 'Rights code',
  权益说明: 'Description of rights',
  大定应用卡券明细: 'Details of card coupons for large fixed applications',
  卡券Code: 'Card coupon code',
  折扣金额: 'Discount amount',
  一级类目: 'First-level category',
  一级类目名称: 'Name of first-level category',
  二级类目: 'Second-level category',
  二级类目名称: 'Name of second-level category',
  领取试驾礼: 'Pick up the test drive',
  '确定发送短信，邀请客户添加企业微信好友？': 'Confirm sending SMS to invite customers to add Enterprise WeChat friend?',
  '批量短信邀请': 'Batch sms invitation',
  '短信加微': 'SMS to wechat addition',
  '添加客户': 'Add customer',
  '批量添加': 'Batch add',
  '添加群成员': 'Add group members',
  '执行人': 'Executor',
  '注：新线索，联系客户未接通时选择': 'Note: Choose when contacting new leads and the customer is not reached.',
  '重新分配文本客服': 'Reassign text customer service',
  '审批状态': 'Approval status',
  '异常订单': 'Exceptional orders',
  '审批时间': 'Approval time',
  '有效线索量': 'Valid lead volume',
  '无金融信息': 'No financial information',
  '选装价格': 'Optional equipment price',
  '选装编码': 'Optional equipment code',
  '创建人': 'Creator',
  '保费金额': 'Premium amount',
  '保单确认时间/承保时间': 'Policy confirmation/inception time',
  '失效时间': 'Expiration time',
  '生效时间': 'Effective time',
  '保单号': 'Policy number',
  '险种类型名称': 'Insurance type name',
  '险种类型code': 'Insurance type code',
  '保险信息': 'Insurance information',
  '任务状态': 'Task status',
  '任务号': 'Task number',
  '处理中': 'In progress',
  '订单明细': 'Order details',
  '大定物料信息': 'Major order material information',
  '物料编码': 'Material code',
  '车系编码': 'Vehicle series code',
  '车型编码': 'Vehicle model code',
  '车型价格': 'Vehicle model price',
  '外观编码': 'Exterior code',
  '外观价格': 'Exterior price',
  '内饰编码': 'Interior code',
  '内饰价格': 'Interior price',
  '数量': 'Quantity',
  '金融': 'Finance',
  '保险': 'Insurance',
  '选装明细': 'Optional equipment details',
  '请输入客户姓名/电话查询': 'Please enter customer\'s name/phone for inquiry',
  '请输入跟进记录': 'Please enter follow-up record',
  '跟进历史': 'Follow-up history',
  '支持上传10张图片，单张最大20M': 'Supports uploading 10 images, max 20mb each',
  '确定提交': 'Confirm submission',
  '补充材料': 'Supplementary materials',
  '请输入改派原因': 'Please enter the reason for reassignment',
  '改派原因': 'Reason for reassignment',
  '请选择门店': 'Please select store',
  '改派至门店': 'Reassign to store',
  '省份城市': 'Province/City',
  '已分配门店': 'Assigned store',
  '门店信息': 'Store information',
  '留言': 'Message',
  '新增': 'New',
  '标准话术': 'Standard Speech',
  '个人话术': 'Personal Speech',
  '收藏话术': 'Collectible Speech',
  '实际交付时间': 'Actual delivery time',
  '下单人': 'Order placer',
  '联系方式': 'Phone',
  '购车人': 'Car buyer',
  '拒绝': 'Reject',
  '同意': 'Agree',
  '向您申请该客户的接待授权': 'Requesting reception authorization for this customer from you',
  '接待授权审核': 'Reception authorization review',
  '填写备注': 'Fill in remarks',
  '快速填写跟进': 'Follow up quickly',
  '留言成功': 'Message successful',
  '常住省份城市': 'Province and city of residence',
  '发送内容': 'Sent content',
  '短信邀请时间': 'SMS invitation time',
  '建群人': 'Group founder',
  '任务生成时间': 'Task creation time',
  '微信客户': 'WeChat customer',
  '批量发送': 'Batch sending',
  '欢迎语任务': 'Welcome message task',
  '重新发送': 'Resend',
  '批量建群': 'Bulk group creation',
  '建群任务': 'Group formation task',
  '短信邀请加微': 'SMS invitation to add on wechat',
  '欢迎语发送任务': 'Welcome message sending task',
  '周交付量/周目标': 'Delivery/Target(week)',
  '月交付量/月目标': 'Delivery/Target(month)',
  '待首次沟通': 'Pending initial communication',
  '待金融申请': 'Pending financial application',
  '待支付首付': 'Pending down payment',
  '待开票申请': 'Pending invoice application',
  '待支付尾款': 'Pending final payment',
  '待确认交付': 'Pending delivery confirmation',
  '待上传交付仪式': 'Pending upload of delivery ceremony',
  '待放款': 'Pending loan disbursement',
  '留存量': 'Retention quantity',
  '周交付量': 'Weekly delivery',
  '日交付量': 'Daily delivery',
  '月交付量': 'Monthly delivery',
  '满意度': 'Satisfaction rate',
  '交付单': 'Delivery Order',
  '保险未出单': 'Insurance not issued',
  '暂无来源': 'No source',
  '交付排行': 'Delivery ranking',
  '预约提车': 'Delivery appointment',
  '未完成': 'No complete',
  '取消收藏': 'Unfavorite',
  '编辑': 'Edit',
  '已收藏': 'Collectible',
  '收藏': 'Collect',
  '修改成功！': 'Modification successful!',
  '新增成功！': 'New success!',
  '适用车型': 'Applicable models',
  '复制': 'Copy',
  '更新时间': 'Update time',
  '按标题/内容/关键词搜索': 'Search by title/content/keyword',
  '于': 'With',
  '下发到店': 'issued to the store',
  '点击下载': 'Click download',
  '基础素材': 'Base material',
  '暂无素材': 'No material',
  '发送': 'Send',
  '图片': 'Image',
  '视频': 'Video',
  '文件': 'File',
  '图文': 'Graphic',
  '请输入搜索关键词': 'Please enter a search keyword',
  '收起': 'Collapse',
  '展开': 'More',
  '代理商大客户': 'Agent fleet',
  '厂端大客户': 'Factory fleet',
  '驳回': 'Reject',
  '已通过': 'Passed',
  '未审核': 'Unaudited',
  '上牌人信息': 'Listing information',
  '企业信息': 'Company information',
  '证件号码不正确': 'The id number is incorrect',
  '最多18个字符仅可为字母和数字': 'Composed of letters and numbers, up to 18 digits',
  '请选择审核结果': 'Please select an audit result',
  '请输入客户姓名/订单编号/顾问/客户手机号': 'Customer name/order number/advisor/customer phone umber',
  '请输入客户姓名/订单编号/客户手机号': 'Customer name/order number/customer phone number',
  '已授权': 'Authorized',
  '未授权': 'Unauthorized',
  '已签署': 'Signed',
  '未签署': 'Unsigned',
  '个人': 'Individual',
  '企业': 'Company',
  '分期': 'Finance',
  '全款': 'Full payment',
  '暂无': 'None',
  '提醒': 'Remind',
  '超期线索': 'Overdue lead',
  '基本信息': 'Basic info',
  '客户姓名': 'Customer name',
  '联系电话': 'Phone number',
  '客户微信': 'Wechat',
  '客户性别': 'Gender',
  '公司名称': 'Company',
  '是否大客户': 'Is fleet',
  '常住地区': 'Address',
  '常住城区': 'Address',
  '居住地区': 'Address',
  '居住小区': 'Detailed address',
  '详细地址': 'Detailed address',
  '其他联系方式1': 'Other phone number 1',
  '其他联系方式2': 'Other phone number 2',
  '购车信息': 'Purchase info',
  '其他信息': 'Other information',
  '意向车型': 'Intention lines',
  '预计试驾时间': 'Expected test drive time',
  '试驾意向等级': 'Test drive intention level',
  '购车意向等级': 'Vehicle purchase intention level',
  '购车时间': 'Vehicle purchase time',
  '共同决策人': 'Co-decision',
  '共同决策人是否试驾': 'Co-decision maker',
  '关注竞品': 'Competitive info',
  '是否置换': 'Is replacement',
  '置换需求': 'Is trade in',
  '非置换': 'No need to trade in',
  '置换': 'Need to trade in',
  '是否需要安装充电桩': 'Need ChargingStation Installation',
  '充电桩安装地址': 'Install address',
  '是否估价': 'Is evaluate',
  '是否有固定车位': 'Parking space available',
  '车辆用途': 'Vehicle usage',
  '购车用途': 'Purpose of purchasing a vehicle',
  '车辆指标': 'Vehicle index',
  '用车指标': 'Vehicle use index',
  '车辆指标类型': 'Vehicle index type',
  '车辆指标来源': 'Vehicle index source',
  '油车指标': 'Oil',
  '电车指标': 'EV',
  '暂无指标': 'None',
  '个人购车': 'Personal purchase',
  '公司购车': 'Company purchase',
  '是': 'Yes',
  '否': 'No',
  '男': 'Male',
  '女': 'Female',
  '返回': 'Return',
  '保存': 'Save',
  '开始时间': 'Start time',
  '结束时间': 'End time',
  '共': 'total',
  '条数据': 'items',
  '姓名': 'Name',
  '电话': 'Phone',
  '请输入客户姓名': 'Enter customer name',
  '请输入联系电话': 'Enter phone no',
  '请选择一个微信好友': 'Select a wechat friend',
  '请选择': 'Please select',
  '请输入': 'Please enter',
  '请输入不少于10个字的跟进记录': 'Please enter follow-up record with at least 10 characters',
  '请输入不少于10个字的跟进内容': 'Please enter follow-up record with at least 10 characters',
  '请选择跟进方式': 'Please select follow mode',
  '请先预览合同': 'Please preview the contract first',
  '确认推送合同至客户签署': 'Confirm to send the contract to the customer for signing',
  '无数据': 'No data',
  '取消': 'Cancel',
  '确认': 'Confirm',
  '全选': 'Select all',
  '分配': 'Allocate',
  '重新分配': 'Reassign',
  '全部': 'All',
  '订单待分配': 'Unallocated',
  '定金待支付': 'Pending payment',
  '待锁单': 'To be locked',
  '合同待确认': 'Contract unconfirmed',
  '待签约': 'Unsigned',
  '车身颜色': 'Exterior',
  '内饰颜色': 'Interior',
  '客户信息': 'Customer',
  '销售顾问': 'Advisor',
  '订单状态': 'Order status',
  '下单时间': 'Order time',
  '订单车型': 'Lines',
  '订单车系车型': 'Order lines',
  '付款方式': 'Payment method',
  '金融状态': 'Financial status',
  '审核中': 'In review',
  '已取消': 'Canceled',
  '重置': 'Reset',
  '待分配': 'To be allocated',
  'PDI待确认': 'PDI be confirm',
  '待预约': 'Pending appointment',
  '预计交车时间': 'Appointment for pick-up time',
  '尾款状态': 'Final payment status',
  '发票状态': 'Invoice status',
  '保险状态': 'Insurance status',
  '交付专员': 'Delivery advisor',
  '预分配': 'Pre-allocation',
  '测试': 'Test',
  '选装': 'Option',
  '车主信息': 'Vehicle owner information',
  '暂无数据': 'No data available',
  '变更顾问': 'Change advisor',
  '选择顾问': 'Select advisor',
  '请输入姓名搜索': 'Enter name to search',
  'week': 'week',
  'weeks': 'weeks',
  '检测未通过': 'Detection failed',
  '跟进记录填写': 'Follow-up',
  '更多': 'More',
  '跟进方式': 'Follow-up method',
  '跟进结果': 'Follow-up result',
  '联系结果': 'Contact result',
  '跟进记录': 'Follow-up record',
  '跟进内容': 'Follow-up record',
  '下次跟进时间': 'Next follow-up time',
  '提交': 'Submit',
  '请选择分配人员': 'Please select assigned personnel',
  '请选择跟进结果': 'Please select follow-up result',
  '请选择联系结果': 'Please select contact result',
  '请选择无效原因': 'Please select invalid reason',
  '请选择战败原因': 'Please select the reason for lost',
  '通过': 'Pass',
  '关键': 'Key',
  '没有更多了': 'No more',
  '有指标': 'Indicators',
  '无指标': 'No indicators',
  '本地不限号': 'No limit indicator',
  '车辆信息登记': 'Vehicle info',
  '意愿确认': 'Purchase insurance confirm',
  '更新': 'Update',
  '购买意愿确认': 'Buy insurance confirm',
  '已确认': 'Confirmed',
  '临牌信息登记': 'Temp license info',
  '临牌变更': 'Temp license change',
  '临牌办理确认': 'Temp license confirm',
  '已办理': 'Had operate',
  '未办理': 'No operate',
  '正式牌提车': 'Office license pick-up',
  '临牌失效时间': 'Temp license time',
  '已完成': 'Complete',
  '试驾中': 'During test drive',
  '未执行': 'No compleate',
  '资料上传': 'File upload',
  '交车仪式记录': 'Deliver record',
  '最少1张，最多拍照6张': 'A minimum of 1 photo, a maximum of 6 photos',
  '上传失败': 'Upload fail',
  '请完成上传': 'Please upload complete',
  '请上传正确图片文件(支持jpg/png/gif)': 'Please upload the correct image file(jpg/png/gif)',
  '车辆状态': 'Vehicle status',
  'loading': 'loading...',
  '请选择车型': 'Please select the vehicle model',
  '上传中': 'Uploading...',
  '预约试驾': 'Book a test drive',
  '预约到店': 'Appointment to store',
  '修改授权': 'Modify the license',
  '取消授权': 'Cancellation of authorization',
  '待确认': 'To be confirmed',
  '审核状态': 'Audit status',
  '店内公共线索领取': 'In-store public lead pick-up',
  '同城公共线索领取': 'Pick up public lead in the same city',
  '隐私号': 'Privacy Number',
  '大客户': 'Fleet',
  '普通客户': 'Ordinary customers',
  '提示': 'Prompt',
  '该线索已授权': 'This lead has been authorized to',
  '可接待': 'for reception',
  '授权后，他人可代替接待客户并填写一次跟进记录': 'After authorization, others can replace to receive clients and fill in a follow-up record once',
  '授权顾问': 'Authorized advisor',
  '请选择授权顾问': 'Please select an authorized advisor',
  '用户来源': 'Channel',
  '最新记录': 'Latest record',
  '意向信息': 'Intention information',
  '审核截止时间': 'Audit deadline',
  '跟进截止时间': 'Follow-up deadline',
  '预约信息': 'Appointment info.',
  '预约时间': 'Appointment time',
  '试驾车辆': 'Test drive vehicle',
  '预约方式': 'Appointment method',
  '预约车系': 'Appointment vehicle series',
  '轨迹': 'Journey',
  '标签': 'Tags',
  '话术': 'Script',
  '试驾等级': 'Test drive level',
  '意向等级': 'Intention level',
  '来源渠道': 'Source channel',
  '预约状态': 'Appointment status',
  '接待顾问': 'Reception advisor',
  '已逾期': 'Overdued',
  '线索所属变更': 'Lead ownership change',
  '线索状态': 'Lead status',
  '逾期时长': 'Overdue duration',
  '取消记录': 'Cancellation record',
  '超时自动取消': 'Automatic cancellation due to timeout',
  '授权类型': 'Authorization type',
  '订单号': 'Order number',
  '车型': 'Vehicle model',
  '总金额': 'Total amount',
  '定金金额': 'Deposit amount',
  '尾款金额': 'Balance amount',
  '已支付金额': 'Amount paid',
  '待支付金额': 'Amount due',
  '元': 'Yuan',
  '分配门店': 'Allocate to store',
  '修改方式': 'Modification method',
  '预约信息修改前': 'Appointment info (before)',
  '预约信息修改后': 'Appointment info (after)',
  '修改信息修改前': 'Modification info (before)',
  '修改信息修改后': 'Modification info (after)',
  '线索归属': 'Lead ownership',
  '意向金': 'Intentional money',
  '跟进备注': 'Follow-up notes',
  '原跟进人': 'Original follower',
  '回收记录': 'Recovery record',
  '共享原因': 'Sharing reason',
  '备注': 'Remarks',
  '订单所属变更': 'Order ownership change',
  '退订原因': 'Unsubscribe reason',
  '退订金额': 'Unsubscribe amount',
  '关注车型': 'Focus on vehicle models',
  '操作时间': 'Operation time',
  '、': ', ',
  'router': {
    'clueManagement': 'Lead management',
    'clueDowngradingManagement': 'Lead Downgrading Management'
  },
  '(已逾期)': '(Overdued)',
  '转交顾问': 'Transfer advisor',
  '跟进事项': 'Follow-up item',
  '审核结果': 'Audit result',
  '审核备注': 'Audit remarks',
  '订单变化': 'Order changes',
  '领取类型': 'Receiving type',
  '分配结果': 'Distribution result',
  '到店时间': 'Arrival time',
  '到店人数': 'Number of people arriving',
  '是否参加活动': 'Attend event',
  '预约信息（修改前）': 'Appointment info (before)',
  '预约信息（修改后）': 'Appointment info (after)',
  '试驾车型（修改后）': 'Test drive model (modified)',
  '试驾方式': 'Test drive mode',
  '是否预约': 'Is it scheduled',
  '试驾车型（修改前）': 'Test drive model (before modification)',
  '试驾时间（修改前）': 'Test drive time (before modification)',
  '试驾时间（修改后）': 'Test drive time (after modification)',
  '试驾车型': 'Test drive model',
  '试驾时间': 'Test drive time',
  '试驾活动': 'Test drive event',
  '线索信息': 'Lead information',
  '添加': 'Add',
  '绑定': 'Bind',
  '请选择来源渠道': 'Please select source channel',
  '客户类型': 'Customer Type',
  '请选择意向信息': 'Please select intention information',
  '请选择分配顾问': 'Please select assign advisor',
  '请选择下次跟进时间': 'Please select next follow-up time',
  '完善客户信息': 'Complete Customer Information',
  '暂存': 'Save Draft',
  '保存并跟进': 'Save and follow-up',
  '请完善车系车型': 'Please complete the series and model of the vehicle',
  '请完善车系': 'Please complete the series of the vehicle',
  '线索验证中': 'Lead Verification in Progress',
  '该线索还未分配跟进人，是否领取 ？': 'The lead has not been assigned to responsible yet. Do you want to claim it?',
  '领取': 'Claim',
  '领取成功': 'Claim successful',
  '您正在跟进该客户，无需重复创建，是否去填写跟进记录？': 'You are following up on this customer and do not need to create a duplicate. Would you like to fill out the follow-up record?',
  '去填写': 'Go fill',
  '】正在跟进该客户，不允许重复创建，您是否去填写跟进记录？': '】We are following up on this customer and duplicate creation is not allowed. Would you like to fill out the follow-up record？',
  '由于您未及时跟进，该线索已被回收至门店公共池，不允许重复创建': 'Due to your failure to follow-up in time, the lead has been recycled to the store public pool, and creating a new one is not allowed',
  '店内公共池已存在同号码线索，不允许重复创建，是否直接领取？': 'There is already a lead with the same number in the store public pool. Do you want to claim it directly?',
  '店内公共池已存在同号码线索，不允许重复创建；由于您无原跟进同角色权限（': 'There is already a lead with the same number in the store public pool, and you do not have the original follow-up role permission (',
  '），无法直接领取': '). Unable to claim directly',
  '请仅选择一个外部联系人进行绑定': 'Please select only one external contact for binding',
  '无效联系人，请重新选择': 'Invalid contact, please select again',
  '请选择正确的车系车型': 'Please select the correct vehicle model',
  '请完成必填字段的填写后再提交': 'Please complete the required fields before submitting',
  '创建成功': 'Creation Successful',
  '请选择试驾意向等级': 'Please select test drive intention level',
  '跟进': 'Follow-up',
  '截止时间：': 'Deadline:',
  '授权他人接待': 'Authorized reception',
  '级': 'Level',
  '置换车辆': 'Replacement vehicle',
  '创建时间': 'Create time',
  '无': 'No data',
  '有车位': 'Has parking',
  '培育人员': 'Cultivation personnel',
  'DCC专员': 'DCC',
  '不支持修改': 'Modification not supported',
  '保存成功': 'Save Successful',
  '请输入您的跟进备注': 'Please enter your follow-up notes',
  '跟进备注成功': 'Follow-up notes submitted successfully',
  '缺少线索id': 'Missing lead id',
  '跟进信息': 'Follow-up information',
  '完善线索信息': 'Modify lead information',
  '提交审核': 'Submit for review',
  '跟进成功': 'Follow-up successful',
  '购车意向评级': 'Vehicle purchase intention rating',
  '预约事项': 'Appointment items',
  '本人是否试驾选配': 'Test drive and option status',
  '竞品了解程度': 'Level of understanding of competitors',
  '进店时间': 'In-store time',
  '进店人数': 'In-store attendance',
  '线索管理': 'Lead management',
  '公共线索': 'Public leads',
  '请选择待分配的线索': 'Please select the lead to be assigned',
  '请输入客户姓名/电话/顾问/隐私号': 'Please enter customer name/phone/advisor/privacy number',
  '今日待跟进': 'Today follow-up',
  '逾期待跟进': 'Overdue follow-up',
  '新建线索': 'New Lead',
  '店内线索查询': 'In-store lead search',
  '店内商机查询': 'In-store business opportunity search',
  '3日内待跟进': 'Follow-up within 3 days',
  '7日内待跟进': 'Follow-up within 7 days',
  '请选择同类型角色跟进的线索': 'Please select lead followed by the same type of role',
  '待首跟': 'First follow-up',
  '未排程': 'Unscheduled',
  '已排程': 'Scheduled',
  '已试驾': 'Test drive completed',
  '已下定': 'Order Placed',
  '已锁单': 'Order locked',
  '已签合同': 'Contract signed',
  '已交车': 'Delivered',
  '跟进中': 'InFollowUp',
  '状态': 'Status',
  '是否加微': 'Whether to add weChat',
  '已加微': 'Already added WeChat',
  '未添加': 'Not added',
  '试驾情况': 'Test drive status',
  '1次': 'Once',
  '多次': 'Many times',
  '是否试驾': 'Whether to test drive',
  '是否即将回收': 'Whether to be recycled soon',
  '线索创建时间': 'Create time',
  '顾问': 'Advisor',
  '最后跟进时间': 'Last follow time',
  '无效原因': 'Invalid reason',
  '请输入完整的电话号码': 'Please enter the complete phone number',
  '来源渠道：': 'Source channel:',
  '意向信息：': 'Intention information:',
  '跟进人：': 'Responsible:',
  '跟进人': 'Responsible',
  '查看详情': 'View details',
  '申请填写更多': 'Apply to fill out more',
  '确定领取该线索': 'Confirm to receive this lead',
  '立即领取': 'Receive now',
  '确定向【': 'Confirm to apply for customer reception authorization for【',
  '】申请客户接待授权？': '】? Apply for customer reception authorization',
  '申请成功': 'Application successful',
  '第一个值必须大于等于': 'The first value must be greater than or equal to',
  '第二个值必须大于等于': 'The second value must be greater than or equal to',
  '第二个值必须大于第一个值': 'The second value must be greater than the first value',
  '待回访': 'Pending follow-up',
  '待回访状态': 'Pending follow-up Status',
  '首次待回访': 'Initial pending follow-up',
  '继续回访': 'Continue follow-up',
  '已回访': 'Already followed-up',
  '回访结果': 'Follow-up result',
  '战败': 'Lost',
  '激活': 'Activate',
  ' 至 ': 'To',
  '请先选择经销商门店': 'Please select a dealership first',
  '请选择一个外部联系人进行绑定': 'Please select an external contact to bind',
  '隐私号码复制成功': 'The privacy number is copied successfully',
  '请使用手机外呼': 'Please use your mobile phone to make outbound calls',
  '版本过低请升级': 'If the version is too low, please upgrade',
  '绑定客户微信': 'Bind customer weChat',
  '添加微信': 'Add weChat',
  '号码复制成功': 'Number copied successfully',
  '查看更多>': 'View more>',
  '置换客户': 'Exchange customers',
  '复制成功': 'Replicating success',
  '待分配线索': 'To be assigned',
  '请输入客户姓名、电话': 'Enter customer name/phone number',
  '留资线索': 'Retention leads',
  '预约试驾线索': 'Appointment test drive lead',
  '下发时间': 'Issuing time',
  '2小时前': '2 hours ago',
  '1小时前': '1 hours ago',
  '自定义': 'Custom',
  '店内超期': 'In-store overdue',
  '同城超期': 'Intra-city overdue',
  '首次超期': 'First overdue',
  '跟进中超期': 'Follow-up on overdue period',
  '领取并分配': 'Claim and allocate',
  '原跟进': 'Original follow-up',
  '回收时间': 'Recycling time',
  '预约待确认': 'Appointment to be confirmed',
  '正在跟进线索': 'Following up on lead',
  '无需领取': 'No need to claim',
  '分配成功': 'Allocation successful',
  '无法跳转': 'Unable to jump',
  '请输入审核意见': 'Please input review comments',
  '开始试驾': 'Start test drive',
  '预约排程': 'Schedule',
  '修改预约': 'Modify',
  '取消预约': 'Cancel',
  '取消提车预约': 'Cancel',
  '审核': 'Approved',
  '到店接待': 'Reception',
  '排程待确认': 'To be confirmed',
  '待试驾': 'Confirmed',
  '预约类型': 'Appointment type',
  '预约车型信息': 'Booking vehicle information',
  '请选择分配线索': 'Please select the allocation lead',
  '请输入客户姓名/电话': 'Please enter the customer name/phone number',
  '试驾用户': 'Test drive user',
  '预约试驾时间': 'Appointment time',
  '实际试驾时间': 'Current test drive time',
  '试驾地点': 'Test drive location',
  '试驾协议': 'Test drive agreement',
  '个人信息保护声明': 'Personal infomation protection agreement',
  '审核意见': 'Review comments',
  '取消备注': 'Cancel remarks',
  '超时取消': 'Timed out cancellation',
  '主动取消': 'Proactively cancel',
  '试驾报告': 'Test drive report',
  '查看报告': 'View report',
  '试驾详情': 'Test drive detail',
  '查看详情': 'View detail',
  '试驾类型': 'Test drive type',
  '取消试驾': 'Cancel',
  '修改试驾': 'Modify',
  '确认出席': 'Confirm',
  '结束试驾': 'Stop',
  '试驾回访': 'Test drive follow-up',
  '请选择试驾车': 'Please select a test drive vehicle',
  '请选择上门试驾地址并填写详细地址': 'Please select the on-site test drive address and fill in the detailed address',
  '请选择时间': 'Please select a time',
  '请确认，结束时间要大于开始时间': 'Please confirm that the end time should be greater than the start time',
  '已预约': 'Booked',
  '时间段已有以下排程，': 'There are already the following schedules for the time period,',
  '已预约信息': 'Appointment info',
  '请选择试驾地点': 'Please select a test drive location',
  '试驾车': 'Test drive vehicle',
  '今': 'Today',
  '开始': 'Begin',
  '结束': 'End',
  '选择时间': 'Select time',
  '周日': 'Sun.',
  '周一': 'Mon.',
  '周二': 'Tues.',
  '周三': 'Wed.',
  '周四': 'Thur.',
  '周五': 'Fri.',
  '周六': 'Sat.',
  '预约到店日期': 'Appointment arrival date',
  '已超时': 'Overdued',
  '待到店': 'No arrived at store',
  '已到店': 'Arrived at store',
  '战败原因': 'Lost reason',
  '暂无评级结果': 'No Rating Results',
  '试驾信息': 'Test drive info',
  '预约车型': 'Appointment vehicle',
  '活动名称': 'Activity name',
  '排程': 'Schedule',
  '到店试驾': 'Store test drive',
  '上门试驾': 'Home test drive',
  '集中试驾': 'Group test drive',
  '年': 'Year',
  '月': 'Month',
  '日': 'Day',
  'DCC': 'DCC',
  '下定时间': 'Deposit time',
  '下定': 'Deposit',
  '查看订单详情': 'View order details',
  '跟进人员': 'Follow-up personnel',
  '试驾状态': 'Test drive status',
  '首页': 'Home',
  '用户': 'Customer',
  '工作台': 'Workspace',
  '下订时间': 'Order time',
  '下订': 'Order',
  '跟进量': 'Follow volume',
  '客流量': 'Guest volume',
  '实际': 'current',
  '目标': 'target',
  '差值': 'Differ',
  '已试驾未选配': 'Test driven but not configured',
  '未试驾未选配': 'Not test driven and not configured',
  '请选择所在地区': 'Please select your location',
  '自然进店': 'Walkin traffic',
  '门店新建': 'Store create',
  '今天': 'Today',
  '手机号复制成功': 'Phone number copied successfully',
  '天': ' day ',
  '小时': ' hours ',
  '线索下发到店时间': 'Distribute time to shop',
  '报告生成中，请稍后查看': 'Report generation in progress, please review later',
  '修改成功': 'Modification successful',
  '修改失败': 'Modification failed',
  '确定': 'Submit',
  '请确认用户是否已线下领取礼物！': 'Please confirm if the user has received the gift offline!',
  '线上领取': 'Online collection',
  '线下领取': 'Offline collection',
  '目标门店': 'Target dealer',
  '转移原因': 'Reason for transfer',
  '申请信息': 'Application information',
  '请输入11位的手机号': 'Please enter a 11-digit mobile phone number',
  '长期战败': 'Long term lost',
  '认领': 'claim',
  '立即认领': 'Claim now',
  '认领成功': 'Claim success',
  '该留资客户已在本店战败，请直接认领跟进': 'The reserved customer has been lost in our store. Please claim and follow-up directly',
  '请选择明确付款方式': 'Please choose a clear payment method',
  '非常明确：客户已经明确付款方式，比如：全款/分期/融资租赁': 'Very clear: The customer has already specified the payment method, such as full payment/installment/financing lease',
  '较明确：有选择付款倾向但并不太了解分期所需首付、月供、资质等基本概念': 'Clear: There is a tendency to choose payment, but not very familiar with the basic concepts of down payment, monthly payment, qualifications, etc. required for installment payments',
  '一般：知道几种付款方式但无选择倾向': 'General: knows several payment methods but has no preference for choice',
  '不太明确：对各种付款方式均不了解': 'Not very clear: not familiar with various payment methods',
  '请选择竞品了解程度': 'Please select the level of understanding of competitors',
  '非常明确：准确说出竞品品牌和车型且已线下体验（进店/试驾）': 'Very clear: accurately state the competitor brand and model and have already experienced it offline (in store/test drive)',
  '较明确：有竞品品牌和车型，但未线下体验（进店/试驾）': 'Clear: There are competing brands and models, but no offline experience (in store/test drive)',
  '一般：仅有竞品的品牌，并无明确车型': 'General: Only competing brands without clear vehicle models',
  '不太明确：不了解任何竞品': 'Not very clear: not familiar with any competitors',
  '暂存成功': 'Temporary storage successful',
  '预计购车时间': 'Estimated purchase time',
  '竞品品牌车型': 'Bench mark',
  '竞品关注点': 'Competitive focus',
  '昨天': 'Yesterday',
  '线索已转出至其他门店，无法查看': 'The lead has been transferred to another store and cannot be viewed.',
  '交付方式': 'Delivery method',
  '到店交付': 'In-store pickup',
  '上牌信息': 'Vehicle registration information',
  '上牌指标': 'License plate criteria',
  '发票信息': 'Invoice information',
  '发票抬头': 'Invoice title',
  '发票号': 'Invoice number',
  '开票时间': 'Invoice date',
  '备注特殊资源需求': 'Note special resource requirements',
  '特殊资源备注': 'Special resource notes',
  '请准确填写客户的特殊资源备注，以便于OTD调整资源': "Please accurately fill in the customer's special resource remarks for OTD to adjust resources", // eslint-disable-line
  '客户需求资料上传': 'Upload customer demand information',
  '返回试驾报告首页': 'Return to home page',
  '辅助驾驶': 'Assisted Driving',
  '全旅程驾驶辅助': 'Travel Assist 3.0',
  '交互式辅助变道': 'Trigger Lane Change',
  '请上传客户需求资料': 'Please upload customer demand information',
  '提交后，OTD会按需要调整资源配置，确定继续提交？': 'After submission, OTD will adjust resource allocation as needed. Are you sure to continue submitting?',
  '查看更多': 'View more',
  '结束试驾成功': 'Test drive completed successfully',
  '申请开票': 'Apply for invoice',
  '开票信息查看': 'Invoice information view',
  '查看资料': 'View documents',
  '操作成功': 'Operation successful',
  '请选择交车时间': 'Please select a delivery time',
  '交付跟进': 'Delivery follow-up',
  '预约验车': 'Appointment for car inspection',
  '车系': 'Series',
  '外饰颜色': 'Exterior color',
  '点检结果': 'Inspection results',
  '请根据要求对车辆进行拍照': 'Please take photos of the vehicle as required',
  '外观': 'Exterior',
  '内饰': 'Interior',
  '电量': 'Electricity level',
  '正前方': 'Directly ahead',
  '左前侧': 'Left front side',
  '正后方': 'Directly behind',
  '右后侧': 'Right rear side',
  '前排位置': 'Front row position',
  '后排位置': 'Rear seats',
  '请到手机端上传!': 'Please upload on your mobile phone!',
  '查看详情>': 'View details>',
  '已点检': 'Checked',
  '未及时点检': 'Unchecked',
  '点检人': 'Inspection personnel',
  '点检时间': 'Inspection time',
  '呼入': 'Incoming',
  '呼出': 'Exhale',
  '请拍摄上传': 'Please shoot and upload',
  '照片': 'photo',
  '业务继承': 'Business inheritance',
  '好友继承': 'Chat inheritance',
  '群主继承': 'Group Leader Inheritance',
  '继承历史': 'Inheriting history',
  '最后跟进时间：': 'Final follow-up time:',
  '选择人员': 'Select personnel',
  '已选择': 'Selected',
  '条': 'item',
  '请至少选择一条数据！': 'Please select at least one piece of data!',
  '离职': 'Resignation',
  '请输入员工名称': '',
  '请选择员工！': 'Please select an employee!',
  '人': 'people',
  '创建时间：': 'Creation time:',
  '请输入离职员工名称': 'Please enter the name of the departing employee',
  '继承业务类型：': 'Inherit business type:',
  '分配人：': 'Assigned by:',
  '分配时间：': 'Allocation time:',
  '线索': 'lead',
  '订单': 'order',
  '交付': 'deliver',
  '继承状态：': 'Inheritance status:',
  '完成时间：': 'Completion time:',
  '接替完毕': 'Replacement completed',
  '等待接替': 'Waiting for replacement',
  '客户拒绝': 'Customer Refusal',
  '接替成员客户达上限': 'Replacing member clients has reached the upper limit',
  '群名称：': 'Group name:',
  '群人数：': 'Group size:',
  '试驾礼品': 'Test drive gift',
  '无领取资格': 'No eligibility to claim',
  '未发送': 'Not sent',
  '已发未领': 'Sent but not claimed',
  '已领取': 'Received',
  '试驾礼品领取方式': 'Test drive gift collection method',
  '线下领取拍照': 'Offline collection of photos',
  '请上传不超过10MB的图片': 'Please upload images up to 10MB in size',
  '请上传线下领取照片': 'Please upload and collect photos offline',
  '请拍摄一张客户领取试驾礼品的照片': 'Please take a photo of the customer receiving a test drive gift',
  '收货人': 'consignee',
  '手机号码': 'phone',
  '收货地址': 'Receiving address',
  '修改礼品': 'Modify Gift',
  '请选择要修改的礼品！': '',
  '订单信息': 'Order information',
  '试驾礼变更记录': 'Test drive gift change record',
  '原试驾礼品': 'Original test drive gift',
  '修改后礼品': 'Modified Gift',
  '变更结果': 'Change results',
  '试驾礼抽奖': 'Test drive gift lottery',
  '活动规则': 'Activity rules',
  '获奖记录': 'Award records',
  '点击抽取试驾礼': 'Click to extract',
  '恭喜您获得': 'Congratulations on obtaining',
  '礼品一份': 'a set of gifts',
  '请填写您的收件地址！': 'please fill in your shipping address!',
  '请输入收货人姓名': 'Please enter the consigneeName',
  '请输入收货人手机号': 'Please enter the consigneePhone',
  '请输入收货人详细地址': 'Please enter the consigneeAddress',
  '省市区': 'Provinces, cities, and districts',
  '请选择省市区': 'Please select a province, city or district',
  '领取成功！': 'Received successfully!',
  '发送成功！': 'Sent successfully!',
  '试驾礼品抽奖': 'Test drive gift lottery',
  '开发中。。。': 'Under development...',
  '该试驾单已抽取过试驾礼！': 'This test drive form has already drawn a test drive gift!',
  '订单创建时间：': 'Order creation time:',
  '支付状态': 'Payment status',
  '收款账号': 'Collection account',
  '车辆PDI结果': 'Car PDI result',
  '车辆PDI检测请求已于': 'Car PDI detection request on',
  '正在等待售后系统反馈PDI结果': 'Waiting for after-sales system feedback PDI result',
  '首付金额': 'Down payment amount',
  '贷款金额': 'Loan amount',
  '开户行': 'Opening bank',
  '未申请': 'Not applied',
  '撤销金融申请': 'Revoke financial application',
  '客户订单为分期订单，还未签署金融合同，请使用工作台中的金融计算器协助客户尽快完成金融合同办理。': 'The customer order is a staged order, and the financial contract has not been signed yet. Please use the financial calculator in the workbench to help the customer complete the financial contract as soon as possible.',
  '关闭': 'Close',
  '整备完成': 'Prepared',
  '整备确认': 'Prepared confirm',
  '整备未完成': 'Prepared not completed',
  '风险': 'Risk',
  '客户详细地址': 'Detail address',
  '交付时间预约': 'Delivery time appointment',
  '交付轨迹': 'Delivery track',
  '交付任务': 'Delivery task',
  '接待门店': 'Reception store',
  '内容': 'Content',
  '交付状态': 'Delivery status',
  '客户跟进': 'Customer follow-up',
  '请先完成车辆发票任务并上传发票': 'Please complete the vehicle invoice task and upload the invoice first',
  '失败': 'fail',
  '异地交付信息': 'Remote delivery information',
  '异地交付方式': 'Remote delivery method',
  '交付地点': 'Delivery address',
  '导航距离里程': 'Navigation distance mileage',
  '请从客户所在地政府所在地导航到交付门店所在位置': 'Please navigate from the government location of the customer to the location of the delivery store',
  '请导航到客户详细地址': 'Please navigate to the customer detailed address',
  '驳回原因': 'Rejection reason',
  '重新发起申请': 'Re-submit application',
  '提交申请': 'Submit',
  '请输入驳回原因': 'Please enter the rejection reason',
  '创建': 'Create',
  '店长': 'Manager',
  '区域': 'Area',
  '总部': 'Headquarters',
  '请上传正确图片文件(支持jpg/png)': 'Please upload the correct picture file (support jpg/png)',
  '文件不能超过': 'File size cannot exceed',
  '客户预约交付时间': 'Customer appointment delivery time',
  '下午': 'afternoon',
  '上午': 'morning',
  '交付工位超限提示': 'Workstation exceeds limit',
  '确认排程': 'Confirm schedule',
  '另选时间': 'Another time',
  '您选择交付数量和时段': 'Selected the number of delivery and time period',
  '交付数量': 'Delivery quantity',
  '预约时段': 'Appointment period',
  '您所选择的交付时间，已经超过门店交付工位上限，工位上限为': 'The delivery time you have selected has exceeded the upper limit of the store delivery workstation, the upper limit is',
  '当前已预约': 'currently reserved',
  '确认继续在该时段进行交付': 'Confirm to continue delivering in this time period',
  'PDI检测结果': 'PDI detection result',
  '检测人员': 'Detection personnel',
  '检测完成时间': 'Detection completion time',
  '检测委托书编号': 'Detection entrustment number',
  '再次PDI': 'Again PDI',
  '同一合同下还有以下订单可以申请开票，是否进行批量开票': 'There are still the following orders under the contract that can be applied for invoicing, whether to batch invoice',
  '上传发票文件(上传发票时，请注意确认信息)': 'Upload invoice file (when uploading the invoice, please note to confirm the information)',
  '未排产': 'To be signed',
  '已排产': 'Producing',
  '已下线': 'Ready for delivery',
  '已发运': 'Dispatched',
  '已入库': 'Arrived in store',
  '已PDI': 'Pdi to be confirmed',
  '已整备': 'Vehicle delivery preparation',
  '判定有效/无效时间': 'Determine valid/invalid time',
  '线索有效性': 'Clue validity',
  '待定': 'undetermined',
  '有效': 'effective',
  '无效': 'invalid',
  '充电桩': 'Charging pile',
  '服务包': 'Service package',
  '交车时间': 'Delivery time',
  '车辆合格证上传': 'Upload vehicle certificate',
  '车辆与交付中心门头合影': 'The picture of the vehicle and the door of the delivery center',
  '购车发票': 'Purchase invoice',
  '购车发票已同步': 'Purchase invoice synchronized',
  '业务分类': 'Business type',
  '试驾': 'Test drive',
  '企微任务': 'Wechat task',
  '跟进时间': 'Follow-up time',
  '首次待跟进': 'First follow-up',
  '待跟进': 'To be followed',
  '即将逾期': 'About to expire',
  '战败回访': 'Defeat return visit',
  '待签署合同': 'To be signed contract',
  '待推送合同': 'To be pushed contract',
  '交付回访': 'Delivery return visit',
  '加微任务': 'Wechat task',
  '添加群成员任务': 'Add group member task',
  '实际执行人': 'Task executor',
  '任务跟进人': 'Task follower',
  '截止时间': 'Deadline',
  '实际完成时间': 'Actual completion time',
  '逾期': 'overdue',
  '确定时间': 'Confirm time',
  '确定日期': 'Confirm date',
  '今日': 'Today',
  '请选择日期': 'Please select a date',
  '请选择起止时间': 'Please select start and end time',
  '确认取消当前客户的预约提车？': 'Confirm to cancel the appointment pickup for the current customer?',
  '车辆状态告知书': 'Vehicle status notification',
  '提车确认书': 'Pickup confirmation',
  '驳回时间': 'Rejection time',
  '故障描述': 'Fault description',
  '维修建议': 'Repair suggestion',
  '委托书信息': 'Entrustment information',
  '进店日期': 'Date of entry',
  '服务顾问': 'Service adviser',
  '超期未提车': 'Timeout to pick-up',
  '请上传附件': 'Please upload the attachment',
  '复制信息': 'Copied',
  '交付单预览': 'Delivery order preview',
  '交付工具': 'Delivery tools',
  '无车位': 'No parking space',
  '【提车确认书线下签署】确认书': '【Pickup confirmation offline signing】Confirmation',
  '车辆状况告知书': 'Vehicle status notification',
  '被驳回,请重新上传': 'Rejected, please re-upload',
  '当前交付单存在风险，需签署车辆状态告知书': 'The current delivery order has risks and needs to sign the vehicle status notification',
  '销售顾问交接备注': 'Advisor handover remarks',
  '更新资料': 'Update information',
  '已分配待处理': 'Allocated to be processed',
  '承保': 'Underwriting',
  '顾问呼出': 'Consultant call out',
  '客户呼入': 'Customer incoming call',
  '关联进店客户': 'Related in store customers',
  '通话时长：': 'Call duration:',
  '未接通': 'Not connected',
  '线下确认书签署': 'Offline confirmation book signing',
  '质损交车': 'Damage delivery',
  '请选择上传客户签署完成的车辆状态告知书': 'Please select the vehicle status notification signed by the customer',
  '已被驳回，请': 'rejected, please',
  '重新上传': 're-upload',
  '交付单已锁定,暂无法操作': 'Delivery order locked, unable to operate temporarily',
  '待支付': 'To be paid',
  '发票到店时间': 'Invoice arrival time',
  '距离里程': 'Distance mileage',
  '费用信息': 'Cost information',
  '里程数（公里）': 'Mileage (kilometers)',
  '预估费用（元）': 'Estimated cost (yuan)',
  '实际花费（元）': 'Actual expenses (yuan)',
  '质损车': 'Damaged vehicle',
  '上传导航截图': 'Upload mileage picture',
  '上传费用图片': 'Upload fee images',
  '车辆存放地': 'Vehicle storage location',
  '运营单位法人代表姓名': 'Operating unit legal representative name',
  '运营单位法人代表手机': 'Operating unit legal representative mobile',
  '运营单位地址': 'Operating unit address',
  '运营地址': 'Operating address',
  '车辆充电桩地址': 'Vehicle charging pile address',
  '定金已支付': 'Deposit paid',
  '已推合同': 'Contract pushed',
  '试驾礼状态': 'Test drive gift status',
  '申请时间：': 'Application time:',
  '申请人：': 'Applicant:',
  '新建申请': 'New application',
  '礼品报缺清单': 'Gift shortage list',
  '删除': 'delete',
  '报缺礼品名称': 'Name of missing gift',
  '请填写报缺礼品名称': 'Please fill in the name of the missing gift',
  '请填写报缺礼品数量(0-999)': 'Please fill in the number of missing gifts (0-999)',
  '请填写正确报缺礼品数量': 'Please fill in the correct number of missing gifts',
  '车辆信息查看': 'View vehicle information',
  '可选择省份城市过滤交付门店，或输入门店名称模糊查询': 'You can select provinces and cities to filter delivery stores, or enter store names for fuzzy queries',
  '门店省份': 'Store province',
  '门店城市': 'Store city',
  '门店名称': 'Store name',
  '选择省份': 'Select province',
  '选择城市': 'Select city',
  '请选择门店省份': 'Please select the province of the store',
  '请选择门店城市': 'Please select the store city',
  '请输入门店名称': 'Please enter the store name',
  '未处理': 'Unprocessed',
  '已处理': 'Processed',
  '查询更多门店': 'Search for more stores',
  '到店指引': 'To-store guide',
  '其他': 'Other',
  '当日外卖消费小票与车架号合影、本地居住证明、本地社保卡等，以上材料任一即可。': 'The current day takeaway consumption ticket and the car frame number photo, local residence certificate, local social security card, etc., any of the above materials can be',
  '2-64位字符': '2-64 bit characters',
  '2-72位中文或英文字符': '2-72 Chinese or English characters',
  '发票更新': 'Invoice update',
  '授权交付': 'Authorized delivery',
  '当前订单': 'Current order',
  '关联订单': 'Associated order',
  '合并线索': 'Merge Clues',
  '该线索有未关闭的预约单，不可发起线索合并': 'This lead has an open appointment form and cannot be merged',
  '确认发送短信，邀请客户添加企业微信好友 ？': 'Confirm sending SMS and inviting customers to add enterprise WeChat friends?',
  '发送短信成功': 'Successfully sent SMS',
  '大客户试驾车': 'Large customer test drive',
  '选择合并至目标线索': 'Choose to merge into the target clue',
  '请输入完整电话号码/客户姓名搜索目标线索': 'Please enter the complete phone number/customer name to search for the target lead',
  '注：功能适用于，人工识别出不同的两条线索为同一个客户。合并后，只需要跟进目标线索，被合并的线索自动战败（微信线索与电话线索合并，默认战败微信线索）': 'Note: The function is applicable to manually identifying two different leads as the same customer. After merging, only the target clues need to be followed up, and the merged clues will be automatically defeated (WeChat clues and phone clues will be merged, default to defeated WeChat clues)',
  '请选择合并目标线索': 'Please select the merge target clue',
  '线索需要同属一个顾问跟进才可合并': 'Clues need to be followed up by the same consultant before they can be merged',
  '下一步': 'next',
  '合并后，被合并线索自动战败，确定继续合并？': 'After the merger, the merged clues will be automatically defeated. Are you sure to continue the merger?',
  '请输入合并原因': 'Please enter the reason for merging',
  '待分配订单': 'To be allocated orders',
  '开票结果': 'Invoice result',
  '上传发票时，请注意VIN号等信息一致': 'When uploading invoices, please note that the VIN number and other information are consistent',
  '发票尚未同步': 'Invoice not yet synchronized',
  '发票尚未同步，暂不支持上传': 'Invoice not yet synchronized, upload not supported temporarily',
  '资料提交': 'Submit materials',
  '合并至': 'Merge to',
  '线索ID': 'Lead ID',
  '合并原因': 'Reason for merger',
  '试乘试驾信息登记': 'Test drive information registration',
  '身份证号': 'ID number',
  '领取试驾礼品': 'Claim test drive gifts',
  '开启试驾盲盒': 'Open blind box for test drive',
  '签署方式': 'method of signing',
  '送车上门信息': 'Car delivery information',
  '送车上门申请': 'Car delivery application',
  '厂端分配': 'Factory allocation',
  '原交付店': 'Original delivery store',
  '新交付店': 'New delivery store',
  '合同签署时间': 'Contract signing time',
  '试驾场景': 'Test drive scenario',
  'Block信息': 'Block information',
  '性别': 'Gender',
  '请选择性别': 'Please select gender',
  '是否了解电车': 'Do you know about trams',
  '任务截止时间': 'Task deadline',
  '是否逾期': 'Is it overdue',
  '收款账户': 'Receiving account',
  '任务创建时间': 'Task creation time',
  '锁单时间': 'Locking time',
  '线索明细': 'Lead management',
  '销售工具': 'Sales Tools',
  '预约明细': 'Appointment detail',
  'Dshowroom': 'Dshowroom',
  '申请退单': 'Apply for a refund',
  '订单状态：': 'Order status:',
  '金融状态：': 'Financial status:',
  '申请原因': 'Reason for application',
  '请输入申请原因': 'Please enter the reason for the application',
  '申请待料': 'Apply for pending materials',
  '请上传正确图片文件，单张最大20M': 'Please upload the correct image file, with a maximum size of 20M per sheet',
  '请上传正确文件附件，单个最大30M': 'Please upload the correct file attachments, with a maximum size of 30M per file',
  '图片至多20张，单张不超过20M，文件至多10个，单个不超过30M': 'Up to 20 images, each not exceeding 20M in size, and up to 10 files, each not exceeding 30M in size',
  '取消申请': 'withdraw',
  '确认取消申请？': 'Are you sure to cancel the application?',
  '确认提交退单申请？': 'Are you sure to submit the refund request?',
  '上传图片': 'Upload image',
  '上传附件': 'Upload attachments',
  '退单申请：': 'Refund application:',
  '查看退单申请': 'View refund request',
  '撤回后，可以修改申请信息重新提交审核，若客户无需退单，则可以撤回后取消退单申请': 'After withdrawal, the application information can be modified and resubmitted for review. If the customer does not need a refund, the refund application can be cancelled after withdrawal',
  '请输入撤回原因': 'Please enter the reason for withdrawal',
  '请输入取消原因': 'Please enter the reason for cancellation',
  '取消则视为客户不需要退单，维持原订单信息': 'If cancelled, it will be deemed that the customer does not need to return the order and the original order information will be maintained',
  '销售门店': 'Sales Store',
  '交付门店': 'Delivery Store',
  '撤销原因': 'Reason for revocation',
  '取消原因': 'Reason for cancellation',
  '审核岗位': 'Audit position',
  '退单审批全流程节点：': 'Node of the entire refund approval process:',
  '流程发起': 'process initiation',
  '城市经理审批': 'City manager approval',
  '大区经理审批': 'Approval by Regional Manager',
  'SO审批': 'SO approval',
  '财务审批': 'Financial approval',
  '申请门店': 'Apply for a store',
  '申请类型': 'Application type',
  '订单变更': 'Order Change',
  '退单': 'Refund order',
  '同配置换车': 'Change cars with the same configuration',
  '注：': 'Note:',
  '战败原因补充': 'Supplementary reasons for defeat',
  '请输入战败补充原因': 'Please enter the supplementary reason for defeat',
  '已解决': 'resolved',
  '单号：': 'Odd Numbers:',
  '未解决': 'Unresolved',
  '工单信息': 'Work order information',
  '提交结果': 'Submit Results',
  '请选择跟进人': 'Please select a follow-up person',
  '请输入跟进结果': 'Please enter the follow-up result',
  '确认提交': 'Confirm submission',
  '请确认是否已经完成了对低满的处理，信息提交后将不能进行修改': 'Please confirm if the low full processing has been completed. Once the information is submitted, it cannot be modified',
  '跟进结果：': 'Follow up results:',
  '门店名称：': 'Store Name:',
  '下载待签署合同': 'Download the contract to be signed',
  '提交确认': 'Submit confirmation',
  '请再次确认以下信息是否已正常填写，此信息提交后将不可更改!': 'Please confirm again whether the following information has been filled in normally. This information cannot be changed after submission!',
  '电子合同编号：': 'Electronic Contract Number:',
  '上传前请确认系统展示的订单信息与上传的合同内呈现的内容一致': 'Please confirm that the order information displayed by the system is consistent with the content presented in the uploaded contract before uploading',
  '请务必确保图片从左到右显示顺序依次对应线下合同的第1页，第2页，第3页...保证后续读取图片生成的合同文件正确。': 'Please ensure that the images are displayed in order from left to right, corresponding to the first, second, and third pages of the offline contract...ensure that the contract files generated from subsequent image readings are correct',
  '合同为图片': 'The contract is a picture',
  '合同为文件': 'The contract is a document',
  '图片至多20张，单张不超过20M，文件至多1个，单个不超过30M': 'Up to 20 images, each not exceeding 20M in size, and up to 1 file, each not exceeding 30M in size',
  '线下合同审批节点：提交审核-店长/Master审核-城市经理审核': 'Offline contract approval nodes: Submit for review - Store Manager/Master review - City Manager review',
  '重新提交审核': 'Resubmit for review',
  '查看线下合同': 'View offline contract',
  '购车相关协议': 'Purchase related agreements',
  '下载失败，请稍后重试！': 'Download failed, please try again later!',
  '客户选择线下签署合同时，请在"更多"下载待签署合同，签署合同前请确认合同内容与订单信息一致': 'When customers choose to sign contracts offline, please download the pending contract in "More" and confirm that the contract content is consistent with the order information before signing the contract',
  '回访结果': 'Follow up results',
  '请选择回访结果': 'Please select the follow-up result',
  '店长/Master审核中': 'Store Manager/Master under review',
  '城市经理审核中': 'Under review by the city manager',
  '确认取消？': 'Confirm cancellation?',
  '继续激活': 'Continue activation',
  '该留资客户已存在本店并无效，请直接认领跟进': 'The registered customer already exists in our store and is invalid. Please claim and follow up directly',
  '长期无效': 'Long term ineffective',
  '获奖时间': 'Award Time',
  '获奖礼品': 'Award winning gifts',
  '收货人信息': 'Recipient Information',
  '下单方式': 'Ordering method',
  '线下下单': 'Offline ordering',
  '线上下单': 'Online ordering',
  '请选择筛选条件后，点击‘查询’按钮筛选数据，': 'After selecting the filtering criteria, click the ‘Search’ button to filter the data,',
  '大区/城市区域/销售门店有联动关系；': 'There is a linkage relationship between regions/urban areas/sales stores;',
  '门店省份/门店城市/销售门店有联动关系；': 'There is a linkage relationship between the province/city of the store and the sales store;',
  '但区域与门店省份城市不联动，支持按区域或省份城市查询账号权限范围内的数据。': 'But the region is not linked to the province and city of the store, and supports querying data within the account\'s permission range by region or province and city.',
  '请至少选择一项': 'Please select at least one option',
  '审核时请重点检查系统实时展示的订单信息与上传的合同内呈现的订单信息一致': 'When reviewing, please focus on checking that the order information displayed in real-time by the system is consistent with the order information presented in the uploaded contract',
  '请确保按照下载时的电子版合同签署，并按照原页码及顺序，逐页上传签署完成的合同': 'Please ensure to sign the electronic version of the contract as downloaded, and upload the signed contract page by page in the original page number and order',
  '请登录SCRM系统PC端，或企业微信电脑版，进入订单详情页面后下载合同': 'Please log in to the SCRM system PC or enterprise WeChat PC version, enter the order details page, and download the contract',
  '线下合同申请：': 'Offline contract application:',
  '任务倒计时': 'Task Countdown',
  '关联已有线索': 'Associate with existing clues',
  '创建新线索': 'Create a new clue',
  '关联微信客户': 'Associate with WeChat customers',
  '确认关联微信客户与线索 ？': 'Confirm the association between WeChat customers and leads?',
  '创建微信线索任务': 'Create WeChat clue task',
  '【待首跟】线索：若跟进任务已逾期，分配后自动生成2小时跟进任务；跟进任务未逾期，分配后任务截止时间不变。' : '【 Waiting for First Follow 】 Clue: If the follow-up task is overdue, a 2-hour follow-up task will be automatically generated after allocation; Follow up tasks are not overdue, and the deadline for assigned tasks remains unchanged.',
  '【战败待审核】【无效待审核】线索：无需生成回访任务；需要您进入“审批中心”及时审批。': '【 Defeated pending review 】 【 Invalid pending review 】 Clues: No need to generate follow-up tasks; You need to enter the \'Approval Center\' for timely approval.',
  '已下单线索：重新分配销售顾问后，订单的任务截止时间不变。': 'Ordered leads: After reassigning sales consultants, the task deadline for the order remains unchanged.',
  '分配销售顾问': 'Assign sales consultants',
  '有顾问保持不变，无顾问则分配至指定销售顾问': 'If there are consultants, they will remain unchanged; if there are no consultants, they will be assigned to designated sales consultants',
  '全部分配至指定销售顾问': 'All assigned to designated sales consultants',
  '请选择销售顾问': 'Please select a sales consultant',
  '【待跟进】线索下次跟进时间': '[To be followed up] Next follow-up time for clues',
  '指定下次跟进时间': 'Specify the next follow-up time',
  '保持原跟进时间不变': 'Keep the original follow-up time unchanged',
  '若设置的时间大于线索原下次跟进时间，则以原下次跟进时间为准': 'If the set time is greater than the original next follow-up time of the clue, the original next follow-up time shall prevail',
  '【战败】线索回访任务': '[Defeat] Clue Follow up Mission',
  '分配立即生成回访任务': 'Assign immediate generation of follow-up tasks',
  '不生成任务': 'Do not generate tasks',
  '请选择回访时间': 'Please choose a follow-up time',
  '【无效】线索回访任务': '[Invalid] Clue Follow up Task',
  '同步线索企业微信好友': 'Synchronize leads with enterprise WeChat friends',
  '同步转移': 'Synchronous transfer',
  '不转移': 'Not transferring',
  '同步转移企业微信好友关系会将原顾问跟该线索的企业微信好友关系解除，新顾问自动添加该客户好友（若客户拒绝则添加失败）': 'Synchronized transfer of enterprise WeChat friend relationship will dissolve the original consultant\'s enterprise WeChat friend relationship with the lead, and the new consultant will automatically add the customer\'s friend (if the customer refuses, the addition will fail)',
  '分配后生成的跟进任务标记': 'Follow up tasks generated after allocation',
  '可标记该批任务为某活动邀约等': 'This batch of tasks can be marked as an event invitation, etc',
  '选择日期': 'Select date',
  '退款中': 'Refund in progress',
  '战败线索': 'Defeat leads',
  '无效线索': 'Invalid leads',
  '最新战败时间：': 'Latest Defeat Date:',
  '最新战败原因：': 'Latest reasons for defeat:',
  '最新无效时间：': 'Latest Invalid Time:',
  '最新无效原因：': 'Latest invalid reason:',
  '最新战败时间': 'Latest Defeat Time',
  '是否长期战败': 'Is it a long-term defeat',
  '是否长期无效': 'Is it ineffective for a long time',
  '店内超期：在总部启用线索回收规则时，店内未跟进的线索逾期，且超过缓冲期（新线索缓冲期为2小时，持续跟进的缓冲期为24小时），触发回收规则后被回收到店内超期池的线索, 需要店长及时重新分配销售顾问。': 'In store overdue: When the lead collection rule is activated at the headquarters, leads that have not been followed up in the store are overdue and have exceeded the buffer period (the buffer period for new leads is 2 hours, and the buffer period for continuous follow-up is 24 hours). Leads that are collected into the in store overdue pool after triggering the collection rule need to be reassigned to sales consultants by the store manager in a timely manner.',
  '同城超期：店内超期的线索，城市经理将其同城共享后，同城的其他门店可主动领取为店内线索。': 'Same city overdue leads: After the city manager shares the overdue leads in the same city, other stores in the same city can actively collect them as in store leads.',
  '公共线索池': 'Public lead pool',
  '客户不愿被联系': 'The customer is unwilling to be contacted',
  '请选择是否长期战败': 'Please choose whether to suffer long-term defeat',
  '选择是，线索战败后不生成战败回访任务': 'Choose Yes, do not generate a follow-up mission after the defeat of the clue',
  '标记为长期战败，线索战败后不生成战败回访任务': 'Marked as long-term defeat, no defeat follow-up missions will be generated after the defeat of the clues',
  '无效回访': 'Invalid follow-up',
  '以下微信客户还未创建或关联线索，需及时创建线索': 'The following WeChat customers have not yet created or associated leads, and need to create leads in a timely manner',
  '若跟进任务已逾期，分配后自动生成2小时跟进任务；跟进任务未逾期，分配后任务截止时间不变。': 'If the follow-up task is overdue, a 2-hour follow-up task will be automatically generated after allocation; Follow up tasks are not overdue, and the deadline for assigned tasks remains unchanged.'
}
