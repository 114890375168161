import axios from 'axios'
import store from '@/store'
import { parseQuery } from '@/utils'
import beforeLoadTaskServices from '@/services/beforeLoadTaskServices'
import qs from 'qs'
import { getWhilePath } from '@/router/whiteList'
import { Toast } from 'vant'
import CryptoJS from 'crypto-js'
import { v4 as uuidv4 } from 'uuid'
import jsSha from 'js-sha256'

const { VUE_APP_BASE_URL } = process.env
const { protocol, host, pathname, search } = window.location
let { corpId, agentId, appId = '', code, isRedirection, token } = parseQuery(search || '')
let loginPromise = null

const requestUtilFactory = baseRequest => {
  return {
    get: baseRequest.bind(null, 'get'),
    post: baseRequest.bind(null, 'post'),
    put: baseRequest.bind(null, 'put'),
    delete: baseRequest.bind(null, 'delete')
  }
}

const axiosIns = axios.create({
  baseURL: VUE_APP_BASE_URL,
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
})

// 接口加签名

const getSign = (signString) => {
  const signature = jsSha.sha256(signString)
  return signature
}

const getHeaders = (url) => {
  const uuid = uuidv4()
  const signString = `${uuid.slice(0, 4)}${url.replace('/qw-third','')}${store.getters.token}${uuid.slice(4)}`
  return {
    random: uuid,
    sign: getSign(signString)
  }
}

axiosIns.interceptors.request.use(config => {
  const signParams = getHeaders(config.url?.split('?')[0])
  if (store.getters.token) {
    config.headers.common['token'] = store.getters.token
  }
  const dealerId = store.getters.userInfo && store.getters.userInfo.dealers ? store.getters.userInfo?.dealers[0]?.id : ''
  config.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
  config.headers.common['language'] = window.navigator.language.toLocaleLowerCase().includes('en') ? 'en' : 'zh'
  config.headers.common['dealerId'] = store.getters.selectedShopId || sessionStorage.getItem('selectedShopId') || dealerId
  config.headers.common = Object.assign({}, config.headers.common, signParams)
  // config.headers.common['language'] = 'en'
  return config
})

// 返参解密
const decrypt = (key, data) => {
  try {
    const aesKey = CryptoJS.enc.Base64.parse(key)
    const base64Data = CryptoJS.enc.Base64.parse(data)
    const srcs = CryptoJS.enc.Base64.stringify(base64Data)
    const decrypted = CryptoJS.AES.decrypt(srcs, aesKey, {
      iv: CryptoJS.enc.Utf8.parse('12345678abcdefgh'),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
  } catch {
    return data
  }
}

axiosIns.interceptors.response.use(
  // response => response.data,
  // error => Promise.reject(error)
  (response) => {
    return decrypt(process.env.VUE_APP_CRYPTOKEY || 'pdGFLdD5mNb/UwLtvuJP+A==',
      ['/scrm-order-service/api/v1/orderReview/download-contract-temp'].includes(response.config.url) ? response : response.data)
  },
  (error) => Promise.reject(error)
)

/**
 * 去微信授权页
 */
const toWxAuthorize = () => {
  console.log('重新微信转发授权 :>> ')
  // 重定向url参数
  const redirectParams = new URLSearchParams(
    search.replace(/^\?/, '')
  )
  redirectParams.delete('code')
  // redirectParams.append('isRedirection', 'y')
  const redirectUri = `${protocol}//${host}${pathname}?${redirectParams.toString()}`
  // 授权地址参数
  const urlParams = new URLSearchParams()
  urlParams.append('appid', corpId)
  urlParams.append('redirect_uri', redirectUri)
  urlParams.append('response_type', 'code')
  urlParams.append('scope', 'snsapi_base')
  const authorizeUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?${urlParams.toString()}#wechat_redirect`
  location.replace(authorizeUrl)
}

let loginCount = 0
const reqeust = (method, ...params) => {
  const isWhileList = getWhilePath() // 白名单页面
  const requestItem = async (resolve, reject) => {
    token && await store.commit('app/setToken', token)
    if ((!store.getters.token) && !code && !isWhileList) {
      toWxAuthorize()
      return
    }
    if (code && !store.getters.token) {
      const redirectParams = new URLSearchParams(
        search.replace(/^\?/, '')
      )
      redirectParams.delete('code')
      loginPromise = beforeLoadTaskServices.login(axiosIns, {
        code,
        agentId: appId || agentId,
        // state: `path=${pathname};corpId=${corpId};appId=${appId};agentId=${agentId}`
        state: `path=${pathname};${redirectParams.toString().replaceAll('&', ';')}`
      }).then((data) => {
        // 跳转派拉登录页面
        if (data.loginUrl && !data.token) {
          location.replace(data.loginUrl)
        } else {
          requestItem(resolve, reject)
        }
      }).catch(() => {
        toWxAuthorize()
        if (navigator.userAgent.includes('wxwork')) {
          window.sessionStorage.removeItem('Token')
        }
      })
    } else {
      // 获取当前登录环境的token
      axiosIns[method](...params).catch(reject).then(result => {
        // 14 token不合法
        if (new Set([14]).has(result.code)) {
          loginCount++
          console.log(`登录态失效错误码:>>${result.msg}`, result.code, isRedirection)
          store.commit('app/setToken', '')
          token = ''
          code = ''
          if (!loginPromise) {
            if (!code && !isWhileList) {
              toWxAuthorize()
            } else if (code && !token && !isWhileList) {
              loginPromise = beforeLoadTaskServices.login(axiosIns, {
                code,
                agentId: appId || agentId
              })
            }
          }
          loginPromise && loginPromise.then(() => requestItem(resolve, reject)).catch(res => {
            if (loginCount <= 4) {
              toWxAuthorize()
            } else {
              Toast.fail('重启登录失效，请关闭应用重新打开')
            }
          })
          return
        }
        loginPromise = null
        loginCount = 0
        resolve(result)
      })
    }
  }
  return new Promise(requestItem)
}

const beforeLoadTasks = new Promise((resolve, reject) => {
  let tasks = []
  const isWhileList = getWhilePath() // 白名单页面
  if (!isWhileList) {
    tasks.push(beforeLoadTaskServices.loadEmployeeDetail) // 加载员工信息
    tasks.push(beforeLoadTaskServices.getQwResource) // 获取企微资源
    tasks.push(beforeLoadTaskServices.loadDict) // 加载字典
    tasks.push(beforeLoadTaskServices.getTestDriveConf) // 获取试驾等级
    tasks.push(beforeLoadTaskServices.getHasMapping) // 获取门店是否开通虚拟号
  }
  // const tasks = [
  //   // 加载员工信息
  //   beforeLoadTaskServices.loadEmployeeDetail,
  //   // 获取企微资源
  //   beforeLoadTaskServices.getQwResource,
  //   // 加载字典
  //   beforeLoadTaskServices.loadDict,
  //   // 获取试驾等级
  //   beforeLoadTaskServices.getTestDriveConf
  // ]
  const run = async () => {
    for (let task of tasks) {
      let params = { corpId, agentId }
      if (store.getters.userInfo && store.getters.userInfo.dealers) {
        params.dealerId = store.getters.userInfo.dealers[0]?.id
      }
      await task(requestUtilFactory(reqeust), params).catch(() => { })
    }
  }
  run().then(resolve).catch(reject)
})

const reqeustTask = async (method, ...params) => {
  await beforeLoadTasks
  return reqeust(method, ...params)
}

export default requestUtilFactory(reqeustTask)
