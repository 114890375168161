export default {
  '请选择交车时间': 'Please select a delivery time',
  '购方税号': 'Buyer\'s tax id',
  '购方名称': 'Buyer\'s name',
  '产地': 'Origin',
  '发动机号码': 'Engine number',
  '合格证号': 'Certificate of conformity number',
  '车辆类型': 'Vehicle type',
  '厂牌型号': 'Brand and model',
  '销售价格': 'Sales price',
  '车辆识别代号': 'Vehicle identification number',
  '车辆信息': 'Vehicle information',
  '车身颜色：': 'Exterior color：',
  '内饰颜色：': 'Interior color：',
  '发起预约': 'Initiate appointment',
  '确认书查看': 'Confirmation document view',
  '确认书签署': 'Confirmation Letter Signing',
  '暂无数据!': 'No data available!',
  '线下签署': 'Offline signing',
  '线上签署': 'Online signing',
  'PDI信息查看': 'Pdi information view',
  '查看PDI记录': 'View pdi record',
  '已上传': 'Uploaded',
  '交车记录上传': 'Delivery record upload',
  '礼品记录上传': 'Gift record upload',
  '信息登记': 'Information registration',
  '信息更新': 'Information update',
  '执行确认': 'Execute confirmation',
  '认证确认': 'Certification confirmation',
  '当前客户未签署交车确认函，请在客户签署成功后，再进行贷后资料上传':
    'The customer has not signed the delivery confirmation yet. please upload the post-loan documents after the customer has signed successfully.',
  '交车确认书签署': 'Delivery confirmation signing',
  '发送合同成功': 'Contract sent successfully',
  '请确认是否完成该任务 ？': 'Are you sure you have completed this task?',
  '操作成功': 'Operation successfully',
  '操作失败': 'Operation failed',
  '交车时间：': 'Delivery time：',
  '预计交车时间：': 'Expected delivery time：',
  '客户信息：': 'Customer information：',
  '车款支付：': 'Payment status：',
  '发票状态：': 'Invoice status：',
  '保险状态：': 'Insurance status：',
  '交付专员：': 'Delivery advisor：',
  '交车确认前置任务检测': 'Pre-task for confirmation',
  '检查未通过，请先完成前置任务': 'Check failed. please complete the pre-tasks first',
  '车辆发票': 'Vehicle invoice',
  '交车仪式记录': 'Delivery ceremony record',
  '车辆SIM卡认证': 'Vehicle sim card certification',
  '车辆激活与绑定': 'Vehicle activation and binding',
  '预览交车确认书': 'Preview delivery document',
  '分配时间': 'Assignment time',
  '查看订单详情': 'View order details',
  '选装：': 'Optional features：',
  '特别关注': 'Special attention',
  '车款支付': 'Payment status',
  '发票状态': 'Invoice status',
  '查看发票': 'View invoice',
  '上传发票': 'Upload invoice',
  '保险状态': 'Insurance status',
  '金融状态': 'Finance status',
  '取消': 'Cancel',
  '上传': 'Upload',
  '请上传正确图片文件(支持jpg/jpeg/png)': 'Please upload a correct image file (supports jpg/jpeg/png)',
  '上传中...': 'Uploading...',
  '上传失败': 'Upload failed',
  '请上传发票图片': 'Please upload an invoice image',
  '上传成功': 'Upload successful',
  '返回': 'Return',
  '提交': 'Submit',
  '资料上传': 'Data upload',
  '交车确认函': 'Delivery confirmation letter',
  '请完成上传': 'Please complete the upload',
  '请输入不少于10个字的跟进记录': 'Please enter a follow-up record with at least 10 characters',
  '跟进信息': 'Follow-up information',
  '请选择联系结果': 'Please select a contact result',
  '暂无法进行交车排程！': 'Delivery scheduling is not available at this time!',
  '品牌保险推荐': 'Brand insurance recommendation',
  '请选择品牌保险推荐': 'Please select a brand insurance recommendation',
  '跟进记录': 'Follow-up record',
  '下次跟进时间': 'Next follow-up time',
  '请选择下次跟进时间': 'Please select the next follow-up time',
  '确定': 'Confirm',
  '请选择时间': 'Please select a time',
  '请确认，结束时间要大于开始时间': 'Please confirm that the end time is later than the start time',
  '操作成功！': 'Operation successful!',
  '请选择分配人员': 'Please select an assignment personnel',
  '到店提车': 'Pick-up at the store',
  '更多': 'More',
  '确认分配': 'Confirm assignment',
  '临时授权': 'Temporary authorization',
  '授权人员': 'Authorized personnel',
  '请选择授权人员': 'Please select',
  '授权期限': 'Authorization period',
  '请选择授权期限': 'Please select',
  '提车时间变更提醒': 'Pick-up time change reminder',
  '重新排程': 'Reschedule',
  '客户提车时间已超期，在变更排程前，请确认客户是否已到店':
    'The customer\'s pick-up time has expired. please confirm if the customer has arrived at the store before changing the scheduling',
  '客户未到店': 'Customer not at the store',
  '客户到店': 'Customer at the store',
  '跟进轨迹': 'Follow-up trajectory',
  '客户临时授权': 'Customer temporary authorization',
  '请选择交付专员！': 'Please select a delivery advisor!',
  '请选择': 'Please select',
  '交车礼品配图': 'Delivery gift image',
  '礼品数量说明': 'Gift quantity description',
  '请输入礼品数量说明': 'Please enter the gift quantity description',
  '请完成必填项填写': 'Please complete the required fields',
  '客户手机号后4位/车辆VIN码/订单号/客户姓名全称':
    'Please enter the last 4 digits of the phone number/vehicle vin code/order number/customer name',
  '共': 'Total ',
  '条数据': ' data',
  '全选': 'Select all',
  '全部': 'All',
  '已整备': 'Prepared',
  '已PDI': 'PDI completed',
  '已入库': 'In stock',
  '已发运': 'Shipped',
  '已交车': 'Delivered',
  '重新分配': 'Reassign',
  '待分配': 'Pending assignment',
  '车系车型': 'Vehicle model',
  '预计交付时间': 'Expected delivery time',
  '实际交车时间': 'Actual delivery time',
  '付款方式': 'Payment method',
  '全款': 'Full payment',
  '分期': 'Installment',
  'PDI状态': 'Pdi status',
  '交付单与车辆状态': 'Delivery note and vehicle status',
  '未申请': 'Not applied',
  '已申请': 'Applied',
  '已开具': 'Issued',
  '自购': 'Self-purchased',
  '未出单': 'Policy not generated',
  '已出单': 'Policy generated',
  '交付专员': 'Delivery advisor',
  '请选择待分配的交车单': 'Please select a pending delivery order for assignment',
  '发票': 'Invoice',
  '重新上传发票': 'Re-upload invoice',
  '机动车登记证': 'Vehicle registration certificate',
  '牌照办理确认': 'License plate processing confirmation',
  '代为办理': 'Processed by agent',
  '自行办理': 'Self-processed',
  '牌照号码登记': 'License plate number registration',
  '车辆行驶证上传': 'Upload vehicle license',
  '请上传正确图片文件(支持jpg/png/gif)': 'Please upload a correct image file (supports jpg/png/gif)',
  '请选择牌照办理': 'Please select license plate processing',
  '请输入牌照号码': 'Please enter the license plate number',
  '交车时间': 'Delivery time',
  '车辆准备清单': 'Vehicle ready checklist',
  '是否风险': 'Is it risky',
  '交付方式': 'Delivery method',
  '到店交付': 'Delivery to store',
  '异地交付': 'Remote delivery',
  '已生产': 'Produced',
  '未排产': 'Unscheduled production',
  '已锁单': 'Locked order',
  '已大定': 'Confirmed',
  '休眠': 'dormancy',
  '待办状态': 'To do status',
  '车辆交付提醒': 'Vehicle delivery reminder',
  '交付单关联车辆已经与': 'The vehicle associated with the delivery note has already been associated with',
  '客户完成交付。': 'the customer has completed the delivery.',
  '请确认是否已经完成交付动作': 'Please confirm if the delivery action has been completed',
  '确认完成交付': 'Confirm completion of delivery',
  '请上传发票后，再确认完成交付': 'Please upload the invoice before confirming the completion of delivery',
  '下单时间': 'Order time',
  '大客户': 'Key Account',
  '购车合同已同步': 'Purchase contract has been synchronized',
  '查看合同详情': 'View contract details',
  '本订单无线上合同副本，请手动拍照合同（含补充单）进行上传': 'This order does not have an online copy of the contract. Please take a photo of the contract (including any supplementary forms) and upload it manually',
  '已排产': 'Production scheduled',
  '已下线': 'Offline',
  '预约到店验车': 'Vehicle inspection in store',
  '异常订单处理': 'Abnormal order processing',
  '更多协议签署': 'More protocol signing',
  '请选择上传': 'Please select upload',
  '预约验车时间': 'Schedule a car viewing time',
  '取消看车': 'Cancel car viewing',
  '客户已到店': 'The customer has arrived at the store',
  '修改时间': 'Modification time',
  '跟进填写': 'Follow-up',
  '确认到店': 'Confirm into store',
  '请上传车辆状态告知书后，再确认完成交付': 'Please upload the vehicle status notification after confirming the completion of delivery',
  '发起签署': 'Initiate signing',
  '查看记录': 'View records',
  '记录更新': 'Record update',
  '质损': 'Quality damage',
  '车辆冻结': 'Vehicle freeze',
  '未锁定': 'Not locked',
  '已锁定': 'Locked',
  '已解锁': 'Unlocked',
  '当前交付单的': 'The current delivery order',
  '车辆被锁定': ' vehicle is locked',
  '当前交付单被': 'The current delivery order is',
  '总部锁定': ' locked by the headquarters',
  '加载失败': 'Loading failed',
}
