import i18nFun from '../router_i18n'
const appName = i18nFun['交付助手']
const routerPath = 'deliveryAssistantPortal'

const routes = [
  {
    path: '/delivery-assistant-portal',
    redirect: 'home',
    component: () => import('@/modules/delivery-assistant-portal/index'),
    meta: {
      title: i18nFun['交付助手'],
      appName,
      isAppIndex: true,
      routerPath,
    },
    children: [
      {
        path: 'home',
        name: 'deliveryAssistantPortalHome',
        component: () => import('@/modules/delivery-assistant-portal/home'),
      },
      {
        path: 'order',
        name: 'deliveryAssistantPortalOrder',
        component: () => import('@/modules/delivery-assistant/index'),
      },
    ],
  },
]

export default routes
